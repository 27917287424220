import styled from "styled-components";
import { default as TypeWriterEffect } from 'typewriter-effect';

function TypeWriter({ text }) {
    return <TypeWriterConsole>
        <TypeWriterEffect
            options={{
                strings: [text],
                autoStart: true,
                loop: true,
                delay: 50,
                pauseFor: 100000, // 10min
                deleteSpeed: 0 // delete immediately
            }}
        />
    </TypeWriterConsole>
}

const TypeWriterConsole = styled.div`
    // text styling
    font-family: 'Fira Mono';
    font-family: "Lucida Console", "Courier New", monospace;
    font-weight: bold;
    font-size:calc(15px + 1vw);
    letter-spacing: 5px; 
    color:#993399;
    text-align:center;

    // layout
    width: 80%;
    padding:2vw;
    margin: auto;
`


export default TypeWriter;