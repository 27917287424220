import styled from "styled-components";

const image_attribution = "https://www.freepik.com/free-vector/3d-wave-purple-pattern-design_15229807.htm#query=digital%20matrix%20light%20purple&position=37&from_view=search&track=ais"
function Footer() {
    return <StyledFooter>
        <p>Copyright &copy; {new Date().getFullYear()} Seed Code | ABN 27343093261</p>
        <p>Background image by
            <a href={image_attribution} target="_blank" rel="noreferrer"> rawpixel.com</a> on Freepik </p>
    </StyledFooter>
}

const StyledFooter = styled.footer`
    background-color: #212529;
    font-size:0.8em;
    color: #fff;
    padding: 5px;
    text-align: center;
    a:link {
        color: #CBC3E3;
        text-decoration: none;
    }
    a:visited {
        color: #CBC3E3;
        text-decoration: none;
    }
`

export default Footer;