import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.jpg';
import styled from 'styled-components'

function NavigationBar() {
    return <Navbar bg="dark" variant="dark">
        <BrandImage />
        <Nav className="me-auto">
            <Nav.Link to="/" as={Link}>Home</Nav.Link>
            <Nav.Link to="/courses" as={Link}>Courses</Nav.Link>
            <Nav.Link to="/faq" as={Link}>FAQ</Nav.Link>
            <Nav.Link to="/about" as={Link}>About Us</Nav.Link>
        </Nav>
    </Navbar >
}

const BrandImage = styled.img.attrs({
    src: `${logo}`
})`
    height: 30px;
    margin-left:10px;
    margin-right:10px;
`;


export default NavigationBar;