import { faSeedling, faTree } from '@fortawesome/free-solid-svg-icons'
import { faPagelines } from "@fortawesome/free-brands-svg-icons"

const difficultyClass = {
    Beginner: {
        icon: faSeedling,
        class: "secondary"
    },
    Intermediate: {
        icon: faPagelines,
        class: "secondary"
    },
    Advanced: {
        icon: faTree,
        class: "secondary"
    }
}

export { difficultyClass }