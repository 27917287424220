import { StandardPageContainer } from '../../components/common/containers.js'
import Accordion from 'react-bootstrap/Accordion';
import {
    backgroundSecondaryStyle, typewriterPaddingStyle, accordionBodyTextStyle

} from '../../components/common/styledCommon.js'
import TypeWriter from '../../components/typewriter/index.js'
import faq from '../../data/faq.json'


function FAQ() {
    return <StandardPageContainer style={backgroundSecondaryStyle}>
        <div style={typewriterPaddingStyle}>
            <TypeWriter text={"FAQ"}></TypeWriter>
            <Accordion style={{
                "marginLeft": "5%", "marginRight": "5%", "marginBottom": "5%",
            }}>
                {faq.map((i, key) => (
                    <Accordion.Item eventKey={key} key={key} >
                        <Accordion.Header>
                            {i.question}
                        </Accordion.Header>
                        <Accordion.Body style={accordionBodyTextStyle}>
                            {i.answer}
                        </Accordion.Body>
                    </Accordion.Item>
                ))
                }</Accordion>
        </div>

    </StandardPageContainer>
}

export default FAQ;