import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";


import './App.scss';
import About from './pages/about/index.js'
import Home from './pages/home/index.js'
import FAQ from './pages/faq/index.js'
import Courses from './pages/courses/index.js'
import Register from './pages/register/index.js'
import Contact from './pages/contact/index.js'

import styled from "styled-components";
import NavigationBar from './components/navigationbar/index.js';
import Footer from './components/footer/index.js';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        document.title = "Seed Code";
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {

    return (
        <PayPalScriptProvider
            options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                components: "buttons",
                currency: "AUD",
                locale: "en_AU"
            }}
        >
            <AppContainer>
                <ScrollToTop />
                <NavigationBar />
                <PageContainer>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/courses" element={<Courses />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/register" element={<Register />} />
                    </Routes>
                </PageContainer>
                <Footer />
            </AppContainer>
        </PayPalScriptProvider>
    )
}

const AppContainer = styled.div`
    margin:0;
    padding:0;
`

const PageContainer = styled.div`
    width:100%;
    min-height: 100vh;   
    -moz-box-shadow:    inset 0 0 30px #fde8f1;
    -webkit-box-shadow: inset 0 0 30px #fde8f1;
    box-shadow:         inset 0 0 30px #fde8f1;
    background-color:#fffbfc;
`

export default App;
