import styled from 'styled-components';
import { StandardPageContainer } from '../../components/common/containers.js'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {
    backgroundSecondaryStyle, typewriterPaddingStyle,

} from '../../components/common/styledCommon.js'
import TypeWriter from '../../components/typewriter/index.js'

function Contact() {
    return <StandardPageContainer style={backgroundSecondaryStyle}>
        <div style={typewriterPaddingStyle}>
            <TypeWriter text={"Contact Us"}></TypeWriter>
        </div>
        <WriteToUsContainer>
            Email us directly via <b>admin@seedcode.com.au</b> or write to us via enquiry form below.
            <br></br>
            <br></br>
            <Form style={{ textAlign: "left" }} >
                <Row className="g-2">
                    <Col md>
                        <FloatingLabel label="First Name" className="mb-3">
                            <Form.Control
                                id="first_name"
                                placeholder="First name"
                                //value={registration.first_name}
                                type="text"
                                //onChange={handleChange}
                                required
                            />
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel label="Last Name" className="mb-3">
                            <Form.Control
                                id="last_name"
                                placeholder="Last name"
                                //value={registration.last_name}
                                type="text"
                                //onChange={handleChange}
                                required
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col md>
                        <FloatingLabel label="Email" className="mb-3">
                            <Form.Control
                                id="email"
                                placeholder="Email"
                                // value={registration.email}
                                type="email"
                                // onChange={handleChange}
                                required
                            />
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel label="Mobile (Optional)" className="mb-3">
                            <Form.Control
                                placeholder="Mobile"
                                id="mobile"
                                // value={registration.mobile}
                                type="tel"
                                pattern=".{10}"
                                title='10-digit number'
                            //onChange={handleChange}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>

                <Row className="g-2">
                    <FloatingLabel>What is your enquiry?</FloatingLabel>
                    <Form.Control
                        id="additional_comments"
                        as="textarea" rows={3}
                        //onChange={handleChange}
                        //value={registration.additional_comments}
                        placeholder=""
                        required
                    />
                </Row>
            </Form>
        </WriteToUsContainer>
    </StandardPageContainer>
}


const WriteToUsContainer = styled.div`
    label {
        color:grey;
    }
    background-color:white;
    padding: 30px;
    border-radius: 10px;
`

// styling
// const MissionStatement = styled.p`
//     font-size: calc(10px + 0.5vw);
//     max-width: 70%;
//     display:inline-flex;
//     color:@dark;
// `

export default Contact;