
import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import { backgroundStyle } from '../../components/common/styledCommon.js'
import TypeWriter from '../../components/typewriter/index.js'
import ParticlesBackground from '../../components/particles/particlesBackground.js'


function Home() {

    const type_text = "Unlock your potential with Seed Code. "
    const main_text = "Instructor-led online coding program for young adults and professionals aspiring to enter the data analytics field. Learn the fundamentals of programming, be introduced to Python and SQL, and apply these skills to solve problems hands-on.";
    const register_text = "Register in Seed Code now."

    return <HomeContainer>
        <div style={backgroundStyle}>
            <Headerline>{type_text}</Headerline>
            <MessageContainer>
                <p>{main_text}</p>
                <Nav.Link to="/courses" as={Link}>
                    <Button variant="primary" size="s">Explore Courses</Button>
                </Nav.Link>
                <ParticlesBackground></ParticlesBackground>
            </MessageContainer>
            <TypeWriter text={register_text}></TypeWriter>
        </div>
    </HomeContainer >
}

const HomeContainer = styled.div`
overflow:hidden;
#tsparticles {
    height: 80px
}
`
const Headerline = styled.div`
    text-align:center;
    font-family: 'Fira Mono';
    font-family: "Lucida Console", "Courier New", monospace;
    font-weight: bold;
    font-size: calc(20px + 1vw);
    letter-spacing: 5px; 
    color:#993399;
    width:80%;
    padding-top:calc(140px + 5vw);
    padding-bottom:10px;
    margin-left:10%;
    margin-right:10%;
    text-align:center;
`
const MessageContainer = styled.div`
    text-align:center;
    width:55%;
    margin: auto;
    font-size: calc(5px + 0.8vw);
    color:#706468;
`

export default Home;