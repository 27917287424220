const particlesConfig = {
    fullScreen: { enable: false },
    "particles": {
        "number": {
            "value": 70,
        },
        "color": {
            "value": "#993399"
        },
        "shape": {
            "type": "circle",
            "stroke": {
                "width": 0,
                "color": "#000000"
            }
        },
        "opacity": {
            "value": 0.3687847739990702,
            "random": true,
            "anim": {
                "enable": true,
                "speed": 1,
                "opacity_min": 0.5,
                "sync": false
            }
        },
        "size": {
            "value": 3.5,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 1,
                "size_min": 0.1,
                "sync": false
            }
        },
        "line_linked": {
            "enable": false,
            "distance": 200,
            "color": "#DDA0DD",
            "opacity": 0.8,
            "width": 1
        },
        "move": {
            "enable": true,
            "speed": 0.25,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
                "enable": true,
                "rotateX": 1282.7296486924183,
                "rotateY": 1200
            }
        }
    },
    "retina_detect": true
}
export default particlesConfig
