import styled from 'styled-components';
import background from "../../assets/background.jpg";
import background_secondary from "../../assets/background_secondary.jpg";

const LineBreak = styled.div`
    height: 10px;
`
const CardImage = styled.img`
    background-color:white;
    width: 12vh;
    height: 12vh;
`

const accordionBodyTextStyle = { textAlign: "left", fontSize: "calc(10px + 0.25vw)" };
const accordionHeaderTextStyle = { margin: "2vw", fontSize: "calc(12px + 0.25vw)" };

const backgroundStyle = {
    backgroundImage: `url(${background})`,
    height: '100%',
    backgroundSize: 'cover',
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundRepeat: 'no-repeat'
};

const backgroundSecondaryStyle = {
    backgroundImage: `url(${background_secondary})`,
    height: '100%',
    backgroundSize: 'cover',
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundRepeat: 'no-repeat'
};

const typewriterPaddingStyle = { paddingTop: "calc(140px - 6vw)" };

export { LineBreak, CardImage, backgroundStyle, backgroundSecondaryStyle, typewriterPaddingStyle, accordionBodyTextStyle, accordionHeaderTextStyle }
