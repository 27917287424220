import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';
import { StandardPageContainer } from '../../components/common/containers.js'
import {
    LineBreak, CardImage,
    backgroundSecondaryStyle, typewriterPaddingStyle,
    accordionBodyTextStyle, accordionHeaderTextStyle
} from '../../components/common/styledCommon.js'
import team from '../../data/team.json'
import TypeWriter from '../../components/typewriter/index.js'

function About() {
    return <StandardPageContainer style={backgroundSecondaryStyle}>
        <div style={typewriterPaddingStyle}>
            <TypeWriter text={"About Us"}></TypeWriter>
        </div>
        <MissionStatement>
            Seed Code's mission is to empower students through high quality teaching material and hands on coaching.
            We aim to provide a supportive and dynamic learning environment where participants can acquire essential
            programming skills to harness the power of data. Our instructional team strives to cultivate a passion for
            coding, promote data literacy, and equip students with the knowledge and tools to succeed in an increasingly
            data-driven world.
        </MissionStatement>
        <br></br>
        <br></br>
        <Accordion defaultActiveKey="0" style={{
            "marginLeft": "5%", "marginRight": "5%", "marginBottom": "5%"
        }} >
            {team.map((i, key) => (
                <Accordion.Item eventKey={key} key={key}>
                    <Accordion.Header >
                        <CardImage src={"profiles/" + i.image} />
                        <div style={accordionHeaderTextStyle}>{
                            i.name}
                            <LineBreak />
                            <i>{i.title}</i>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body style={accordionBodyTextStyle}>
                        <p>{i.background}</p>
                        <p>{i.experience}</p>

                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    </StandardPageContainer>
}

// styling
const MissionStatement = styled.p`
    font-size: calc(10px + 0.5vw);
    max-width: 70%;
    display:inline-flex;
    color:@dark;
`

export default About;